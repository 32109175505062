const getStyles = (theme) =>({
    loginDesktop: {
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      width: '100%',
      height: "100vh"
    },
    loginMobile: {
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      width: '100%',
      height: "23vh"
    },
    positionTitle: {
      position: 'absolute',
      bottom: '10%',
      left: '50%',
      transform: 'translate(-50%, 0%)',
  
    }, 
  });
  
  export default getStyles;
  
  