import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'gatsby';
import * as Yup from "yup";
import { Field, Formik, Form } from 'formik';
import { capitalizeFirst } from '../../utils/format';
import { useStyles } from "react-styles-hook";
import getStyles from './styles';

const LoginClient = ({ formValue, setFormValue, submit, lang, from }) => {
  const classes = useStyles(getStyles());
  const dispatch = useDispatch();

  const initialValues = {
    email: formValue.email,
    password: formValue.password,
  };

  // check email & password for signIn
  const { loginLoading, loginErrorMessage, isAuthenticating } = useSelector((state) => (
    {
      loginLoading: state.getIn(['root', 'loginLoading']),
      loginErrorMessage: state.getIn(['root', 'loginErrorMessage']),
      isAuthenticating: state.getIn(['root', 'isAuthenticating'])

    }
  ));

  // Form Validation Schema
  const ValidationSchema = Yup.object().shape({
    email: Yup.string()
      .required("Veuillez renseigner votre email")
      .email("Le format de votre email est incorrect")
      .nullable(),
    password: Yup.string()
      .required("Veuillez renseigner votre mot de passe")
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$&+,:;=?@#|'<>.^*()%!-])[A-Za-z\d$&+,:;=?@#|'<>.^*()%!-]{8,}$/,
        "Le format de votre mot de passe est incorrect"
      )
      .nullable()
  });

  return (
    <div className="sm:mx-auto sm:w-full sm:max-w-lg">
      <div className="w-full mx-auto">
        <Formik
          initialValues={initialValues}
          validationSchema={ValidationSchema}
          onSubmit={submit}
        >
          {({ errors, touched, values, handleChange }) => (
            <Form className="mx-auto w-5/6 flex flex-col justify-center items-center">
              <div className="w-full flex flex-col md:flex-row justify-between items-start md:items-center mb-6">
                <div className="w-full">
                  <label className="pb-2 block text-s text-grey font-medium">{capitalizeFirst("e-mail")}</label>
                  <Field
                    id="email"
                    name="email"
                    type="email"
                    value={values.email ? values.email : ""}
                    onChange={(e) => {
                      handleChange(e);
                      setFormValue((prevState) => ({
                        ...prevState,
                        [e.target.name]: e.target.value.toLowerCase()
                      }));
                    }}
                    style={classes.roundedBorder}
                    className={`w-full ${errors.email && touched.email ? 'border-red focus:border-red' : 'border-grey-light focus:outline-none focus:ring-orange-light focus:border-orange-dark'}`}
                  />
                  {errors.email && touched.email && (
                    <p
                      style={classes.error}
                      className="px-2 pt-2 text-center md:text-left text-orange-chocolate"
                    >
                      {errors.email}
                    </p>
                  )}
                </div>
              </div>

              <div className="w-full flex flex-col md:flex-row justify-between items-start md:items-center mb-6">
                <div className="w-full">
                  <label className="pb-2 block text-s text-grey font-medium">{capitalizeFirst("mot de passe")}</label>
                  <Field
                    id="password"
                    name="password"
                    type="password"
                    value={values.password}
                    onChange={(e) => {
                      handleChange(e);
                      setFormValue((prevState) => ({
                        ...prevState,
                        [e.target.name]: e.target.value
                      }));
                    }}
                    style={classes.roundedBorder}
                    className={`w-full ${errors.password && touched.password ? 'border-red focus:border-red' : 'border-grey-light focus:outline-none focus:ring-orange-light focus:border-orange-dark'}`}
                  />
                  {errors.password && touched.password && (
                    <p
                      style={classes.error}
                      className="px-2 pt-2 text-center md:text-left text-orange-chocolate"
                    >
                      {errors.password}
                    </p>
                  )}
                </div>
              </div>
              <button
                className="mt-4 md:mt-0 text-white bg-orange-dark hover:bg-orange-chocolate border border-transparent rounded-lg hover:border-0 py-2 px-4 focus:outline-none focus:ring-0"
                disabled={loginLoading || isAuthenticating}
                type="submit"
              >
                {(loginLoading || isAuthenticating) ? 'Connexion...' : 'Se connecter'}
              </button>
              {/* login error message */}
              <div className='my-4 text-center'>
                {(loginErrorMessage)
                  && (
                    <p style={classes.error}>{loginErrorMessage}</p>
                  )}
              </div>
              {from &&
                <Link to={`/${lang}/signup`} state={{ from: from }} className="bg-green-400 my-1 mt-3 text-grey-dark text-xs flex justify-center underline underline-offset-1">
                  Créer un compte
                </Link>
              }
              {!from &&
                <Link to={`/${lang}/signup`} className="bg-green-400 my-1 mt-3 text-grey-dark text-xs flex justify-center underline underline-offset-1">
                  Créer un compte
                </Link>
              }
              <Link to={`/${lang}/password`} className="bg-green-400 my-1 text-grey-dark text-xs flex justify-center underline underline-offset-1">
                Mot de passe oublié ?
              </Link>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
};

export default LoginClient