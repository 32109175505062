import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { navigate } from 'gatsby-link';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';
import Layout from "../../components/Layout";
import LoginClient from '../../components/LoginClient';
import { loginLaunched, getCurrentSessionLaunched } from '../../state/reducer';
import NavBarMenu from "../../components/NavBarMenu";
import CoverImage from '../../components/CoverImage';
import signUpMobile from '../../../assets/signupMobile.png';
import LoginImage from '../../components/LoginImage';
import generateMeta from '../../utils/meta';

const LoginPage = ({ data, pageContext }) => {
  // SEO
  const initMetas = [{
    name: 'og:url',
    content: `${process.env.GATSBY_WEBSITE_URL}${pageContext.currentPath}`,
  }];

  const metas = generateMeta(initMetas, data?.markdownRemark?.frontmatter);

  // Check if user is connected
  const dispatch = useDispatch();

  const { isAuthenticated } = useSelector((state) => ({
    isAuthenticated: state.getIn(['root', 'isAuthenticated']),
  }));

  useEffect(() => {
    dispatch(getCurrentSessionLaunched());
  }, [dispatch]);

  if (isAuthenticated) {
    navigate(`/${pageContext.locale}/account`);
  }

  // used for navBarMenu
  const sortedFirstLevelCategorySlugs = ["femme", "enfant", "homme", "coaching-capillaire"];

  // Login Form 
  const [formValue, setFormValue] = useState({
    email: null,
    password: null,
  });

  const login = () => {
    dispatch(loginLaunched(formValue));
  };

  return (
    <Layout
      lang={pageContext.locale}
      currentPath={pageContext.currentPath}
      firstLevelCategories={data.menuFirstLevel.edges.sort((firstLevelCategory1, firstLevelCategory2) => {
        return (
          sortedFirstLevelCategorySlugs.indexOf(firstLevelCategory1.node.slug) - sortedFirstLevelCategorySlugs.indexOf(firstLevelCategory2.node.slug)
        )
      })}
      secondLevelCategories={data.menuSecondLevel.edges}
      thirdLevelCategories={data.menuThirdLevel.edges}
    >
      <Helmet>
        <html lang={pageContext.locale} />
        <title>Connexion</title>
        {metas && metas.map(meta => (
          <meta key={meta.name} name={meta.name} content={meta.content} />
        ))}
      </Helmet>
      <NavBarMenu
        firstLevelCategories={data.menuFirstLevel.edges.sort((firstLevelCategory1, firstLevelCategory2) => {
          return (
            sortedFirstLevelCategorySlugs.indexOf(firstLevelCategory1.node.slug) - sortedFirstLevelCategorySlugs.indexOf(firstLevelCategory2.node.slug)
          )
        })}
        secondLevelCategories={data.menuSecondLevel.edges}
        thirdLevelCategories={data.menuThirdLevel.edges}
        lang={pageContext.locale}
      />
      <CoverImage
        imageDesktop={signUpMobile}
        imageMobile={signUpMobile}
        title="Connexion"
        altDesktop="Connexion"
        altMobile="Connexion"
      />
      <div className='min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8'>
        <LoginClient
          formValue={formValue}
          setFormValue={setFormValue}
          submit={() => login()}
          lang={pageContext?.locale}
        />
        {/* <div className='md:w-2/5'>
          <LoginImage
            imageDesktop={signUpDesktop}
            imageMobile={signUpMobile}
            title="Connexion"
            altDesktop="beautiful woman hair"
            altMobile="palm trees"
          />
        </div> */}
      </div>

    </Layout>
  )
}

export default LoginPage

export const query = graphql`
    query( $locale: String! ) {
    menuFirstLevel: allContentfulCategorySve(filter: { slug: { in: ["femme", "enfant", "homme", "coaching-capillaire"] }, node_locale: {eq: $locale}}) {
      edges {
        node {
          slug
          categoryName
        }
      }
    }
    menuSecondLevel: allContentfulCategorySve(filter: {parentCategory: { slug: { in: ["femme", "enfant", "homme", "coaching-capillaire"] }, node_locale: {eq: $locale}}}
    sort : {fields: [slug], order: ASC}) {
      edges {
        node {
          slug
          categoryName
          categoryEncartTitle
          parentCategory {
            slug
            categoryName
            categoryEncartTitle
          }
        }
      }
    }
    menuThirdLevel: allContentfulCategorySve(filter: {parentCategory: {parentCategory: { slug: { in: ["femme", "enfant", "homme", "coaching-capillaire"] }, node_locale: {eq: $locale}}}}
    sort : {fields: [slug], order: ASC}) {
      edges {
        node {
          slug
          categoryName
          categoryEncartTitle
          parentCategory {
            slug
            categoryName
            categoryEncartTitle
            parentCategory {
              slug
              categoryName
              categoryEncartTitle
            }
          } 
        }
      }
    }
    markdownRemark(fileAbsolutePath: {regex: "/login.md/"}) {
      html
      frontmatter {
          title
          description
          ogtitle
          ogdescription
      }
    }
  }
  `