import React from "react";
import getStyles from "./styles";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useStyles } from "react-styles-hook";
import { capitalizeFirst } from '../../utils/format';

const LoginImage = ({
  imageDesktop,
  imageMobile,
  title,
  altDesktop,
  altMobile,
  t
}) => {
  const classes = useStyles(getStyles());

  return (
    <div className="grid grid-cols-1 z-0 h-full">
      <div className="relative h-full">
        {{ imageDesktop } ? (
          <img
            src={imageDesktop}
            alt={altDesktop}
            style={classes.coverDesktop}
            className="object-cover hidden md:block w-full h-96"
          />
        ) : (
          <div
            className="place-self-center mb-10 z-10"
            style={classes.coverTitle}
          >
            <h2 className="font-titleFont text-black text-center text-xxl">
              {capitalizeFirst(t(title))}
            </h2>
          </div>
        )}
        {{ imageMobile } ? (
          <img
            src={imageMobile}
            alt={altMobile}
            style={classes.coverMobile}
            className="object-cover block md:hidden h-full"
          />
        ) : (
          <div
            className="place-self-center mb-10"
            style={classes.coverTitle}
          >
            <h2 className=" absolute font-titleFont text-black text-center text-xxl">
              {capitalizeFirst(t(title))}
            </h2>
          </div>
        )}
        <div style={classes.positionTitle}>
          <h2 className="font-titleFont text-white text-center text-xxl md:text-xxl">
            {capitalizeFirst(t(title))}
          </h2>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(LoginImage);

LoginImage.propTypes = {
  title: PropTypes.string,
  imageMobile: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  imageDesktop: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  altDesktop: PropTypes.string,
  altMobile: PropTypes.string,
};
